<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="custom-container">
          <h1 class="title">Liste des Karnott ({{ this.devices.filter(d => !d.associate_to_date).length }})</h1>
          <div class="buttons">
            <b-button class="button is-primary" @click.prevent="showModal()"> Lier des Karnott </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-container">
      <div class="buttons">
        <b-button type="is-info" @click.prevent="showOTAModal()"> Programmer des OTA </b-button>
        <b-button type="is-warning" @click.prevent="showChangeStatusModal()">
          Modifier le statut des Karnott sélectionnés
        </b-button>
        <b-button type="is-warning" @click.prevent="showDesassociateModal()">
          Désassocier les Karnott sélectionnés
        </b-button>
      </div>
      <b-table :data="currentDevices" :checked-rows.sync="checked" checkable>
        <b-table-column v-slot="props" field="id" label="ID"> #{{ props.row.id }} </b-table-column>
        <b-table-column v-slot="props" field="health" label="Santé" class="health-td">
          <p class="tag is-danger is-large" v-if="isDeviceOutOfService(props.row)">
            <b-icon icon="skull-crossbones" size="is-small"></b-icon>
          </p>
          <p class="tag is-success is-large" v-if="isDeviceOk(props.row)">
            <b-icon icon="thumb-up" size="is-small"></b-icon>
          </p>
          <p class="tag is-warning is-large" v-if="isDeviceDown(props.row)">
            <b-icon icon="alert-circle" size="is-small"></b-icon>
          </p>
          <p class="tag is-danger is-large" v-if="isDeviceCritical(props.row)">
            <b-icon icon="alert" size="is-small"></b-icon>
          </p>
        </b-table-column>
        <b-table-column v-slot="props" field="batterie" label="Batt." class="health-td">
          <b-icon
            :icon="getBatteryIcon(props.row.external_battery_level, props.row.charging_state)"
            size="is-small"
          ></b-icon>
        </b-table-column>
        <b-table-column v-slot="props" field="serialnumber" label="Numéro de série">
          <router-link target="_blank" :to="getLink(props.row)" class="button is-text">
            {{
              props.row.name === props.row.serialnumber
                ? props.row.serialnumber
                : props.row.serialnumber + " (" + props.row.name + ")"
            }}
          </router-link>
        </b-table-column>
        <b-table-column v-slot="props" field="firmware_name" label="Nom du firmware">
          {{ props.row.firmware_name || "-" }}
        </b-table-column>
        <b-table-column v-slot="props" field="hardware_version" label="Version hardware">
          {{ props.row.hardware_version || "-" }}
        </b-table-column>
        <b-table-column v-slot="props" field="battery_level" label="Batterie (SOH / SOC)">
          {{ props.row.battery_level }} / {{ props.row.external_battery_level }}
        </b-table-column>
        <b-table-column v-slot="props" field="status" label="Statut">
          {{ props.row.status }}
        </b-table-column>
        <b-table-column v-slot="props" field="current_state_with_cluster" label="Statut de liaison">
          <b-select
            :loading="changeStateLoading[props.row.id]"
            @input="updateDeviceHasClusterState(props.row)"
            v-model="props.row.current_state_with_cluster"
          >
            <option v-bind:key="option" v-for="option in DEVICE_STATES_WITH_CLUSTER" :value="option" :disabled="option === UNDEFINED_DEVICE_STATE_WITH_CLUSTER">
              {{ fmtDeviceStateWithCluster(option) }}
            </option>
          </b-select>
        </b-table-column>
        <b-table-column v-slot="props" field="type" label="Type">
          {{ props.row.device_model.label }}
        </b-table-column>
        <b-table-column v-slot="props" field="current_equipment" label="Equipement courant">
          {{ getEquipmentName(props.row.current_equipment_instance_id) }}
        </b-table-column>
        <b-table-column v-slot="props" field="associate_date" label="Date d'association">
          {{ formatDate(props.row.associate_date) }}
        </b-table-column>
        <b-table-column v-slot="props" field="associate_to_date" label="Date de fin d'association">
          {{ formatDate(props.row.associate_to_date) }}
        </b-table-column>
        <b-table-column v-slot="props" field="lastinfo_date" label="Date de dernière connexion">
          {{ formatDate(props.row.lastinfo_date) }}
        </b-table-column>
        <b-table-column v-slot="props" field="actions" label="Action">
          <a class="button is-danger is-fullwidth" @click.prevent="unlinkKarnott(props.row)">
            <b-icon icon="link-off" size="is-small"></b-icon>
          </a>
          <UnlinkDeviceTwoDaysLaterButton :cluster="cluster" :device="props.row" :onSuccess="loadDevices" />
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info"> Aucun Karnott </b-message>
        </template>
      </b-table>
    </div>

    <div class="hero hero--title">
      <div class="hero-body">
        <div class="custom-container">
          <h1 class="title">Liste des Karnott qui ont été associés par le passé ({{ pastDevices.length }})</h1>
        </div>
      </div>
    </div>
    <div class="custom-container">
      <b-table :data="pastDevices">
        <b-table-column v-slot="props" field="id" label="ID"> #{{ props.row.id }} </b-table-column>
        <b-table-column v-slot="props" field="health" label="Santé" class="health-td">
          <p class="tag is-success is-large" v-if="isDeviceOk(props.row)">
            <b-icon icon="thumb-up" size="is-small"></b-icon>
          </p>
          <p class="tag is-warning is-large" v-if="isDeviceDown(props.row)">
            <b-icon icon="alert-circle" size="is-small"></b-icon>
          </p>
          <p class="tag is-danger is-large" v-if="isDeviceCritical(props.row)">
            <b-icon icon="alert" size="is-small"></b-icon>
          </p>
        </b-table-column>
        <b-table-column v-slot="props" field="serialnumber" label="Numéro de série">
          <router-link target="_blank" :to="getLink(props.row)" class="button is-text">
            {{ props.row.serialnumber }}
          </router-link>
        </b-table-column>
        <b-table-column v-slot="props" field="firmware_name" label="Nom du firmware">
          {{ props.row.firmware_name || "-" }}
        </b-table-column>
        <b-table-column v-slot="props" field="hardware_version" label="Version hardware">
          {{ props.row.hardware_version || "-" }}
        </b-table-column>
        <b-table-column v-slot="props" field="battery_level" label="Batterie (SOH / SOC)">
          {{ props.row.battery_level }} / {{ props.row.external_battery_level }}
        </b-table-column>
        <b-table-column v-slot="props" field="status" label="Statut">
          {{ props.row.status }}
        </b-table-column>
        <b-table-column v-slot="props" field="current_state_with_cluster" label="Statut de liaison">
          <b-select
            :loading="changeStateLoading[props.row.id]"
            @input="updateDeviceHasClusterState(props.row)"
            v-model="props.row.current_state_with_cluster"
          >
            <option v-bind:key="option" v-for="option in DEVICE_STATES_WITH_CLUSTER" :value="option" :disabled="option === UNDEFINED_DEVICE_STATE_WITH_CLUSTER">
              {{ fmtDeviceStateWithCluster(option) }}
            </option>
          </b-select>
        </b-table-column>
        <b-table-column v-slot="props" field="type" label="Type">
          {{ props.row.device_model.label }}
        </b-table-column>
        <b-table-column v-slot="props" field="current_equipment" label="Equipement courant">
          {{ getEquipmentName(props.row.current_equipment_instance_id) }}
        </b-table-column>
        <b-table-column v-slot="props" field="associate_date" label="Date d'association">
          {{ formatDate(props.row.associate_date) }}
        </b-table-column>
        <b-table-column v-slot="props" field="associate_to_date" label="Date de fin d'association">
          {{ formatDate(props.row.associate_to_date) }}
        </b-table-column>
        <b-table-column v-slot="props" field="lastinfo_date" label="Date de dernière connexion">
          {{ formatDate(props.row.lastinfo_date) }}
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info"> Aucun Karnott </b-message>
        </template>
      </b-table>
    </div>
    <b-modal :active="isModalActive" :on-cancel="closeModal">
      <form @submit.prevent="submitLinkKarnottForm">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Selectionner les Karnott</p>
          </header>
          <section class="modal-card-body">
            <b-message type="is-danger" v-if="linkKarnottWarningMessages && linkKarnottWarningMessages.length > 0">
              <p :key="message" v-for="message in linkKarnottWarningMessages">{{ message }}</p>
            </b-message>
            <b-field label="Rechercher les Karnott">
              <b-taginput
                :allow-new="false"
                :data="deviceSearchResults"
                :allow-duplicates="false"
                field="serialnumber"
                icon="label"
                placeholder="AAAA A01"
                autocomplete
                v-model="selectedKarnott"
                @typing="filterAction"
                @input="() => (this.deviceSearchResults = [])"
                :loading="isSearchKarnottLoading"
              >
                <template slot="empty" v-if="!isSearchKarnottLoading">
                  <b-message type="is-info">
                    Aucun résultat (la recherche doit comporter au moins 2 caractères et ne pas être "aa" ou "aaa")
                  </b-message>
                </template>
              </b-taginput>
            </b-field>
            <b-field label="Statut de la liaison">
            <b-select v-model="newDeviceState">
              <option v-bind:key="option" v-for="option in DEVICE_STATES_WITH_CLUSTER_FOR_SELECT" :value="option">
                {{ fmtDeviceStateWithCluster(option) }}
              </option>
            </b-select>
          </b-field>
            <b-field label="Date d'association">
              <b-input type="date" v-model="newAssociateDate" />
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
          <button class="button is-info"  :disabled="selectedKarnott.length === 0 || !newDeviceState">Modifier</button>
        </footer>
      </form>
    </b-modal>
    <b-modal :active="isOTAModalActive" :on-cancel="closeModal">
      <div class="modal-card">
        <header class="modal-card-head">Créer une OTA</header>
        <section class="modal-card-body">
          <div class="panel">
            <p class="panel-heading">Sélectionner la nouvelle version du firmware</p>
            <div class="panel-block">
              <div class="control">
                <div class="field">
                  <div class="control is-expanded">
                    <div class="js-select-new-firmware select is-fullwidth">
                      <b-autocomplete
                        v-model="searchInput.firmwareNameForNew"
                        @select="selectNewFirmware"
                        :open-on-focus="true"
                        :keep-first="true"
                        :data="searchFirmwaresForNew"
                        field="fullVersion"
                      />
                    </div>
                  </div>
                </div>
                <b-field>
                  <b-checkbox v-model="forceOta">Force OTA</b-checkbox>
                </b-field>
                <div class="field is-expanded">
                  <button class="button is-success is-fullwidth js-submit-create-ota" @click="submitForm">
                    Mettre à jour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <ChangeStatusModal
      :devices="checked"
      :onClose="closeChangeStatusModalActive"
      :isActive="isChangeStatusModalActive"
      :onSubmitSuccess="loadDevices"
    />
    <DesassociateKarnottModal
      :devices="checked"
      :onClose="closeDesassociateModal"
      :isActive="isDesassociateModalActive"
      :unlinkKarnott="unlinkSelectedKarnott"
    />
  </div>
</template>

<style scoped>
.hero h1.title {
  display: inline-block;
  margin-bottom: 0;
}

.hero .buttons {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.modal-card {
  min-height: 250px;
  width: 100%;
}
.health-td .tag {
  width: 100%;
}

.custom-container {
  max-width: 95%;
  margin: 0 20px;
}
</style>
<script>
import { mapActions, mapState } from "vuex";
import { getClusterDevices, searchDevices, updateAssociateDate } from "Api/device";
import {
  linkDeviceToCluster,
  unLinkDeviceToCluster,
  updateDeviceHasClusterState,
  getDeviceHasClusterHistory
} from "Api/deviceHasCluster";
import { getClusterEquipment } from "Api/equipment";
import { FETCH_FIRMWARES_VERSION_ACTION } from "Stores/devices";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { CREATE_MULTIPLE_OTA_WITH_DEVICES_ACTION } from "Stores/ota";
import { formatDate, sortById, stringToDate, dateToLocaleString } from "Utils";
import { DEVICE_STATES_WITH_CLUSTER, UNDEFINED_DEVICE_STATE_WITH_CLUSTER, DEVICE_STATES_WITH_CLUSTER_FOR_SELECT, DEVICE_STATES_WITH_CLUSTER_LABEL } from "Constants";
import { isDeviceOk, isDeviceDown, isDeviceCritical, isDeviceOutOfService, callApiWithSignal } from "Utils";
import ChangeStatusModal from "Components/devices/ChangeStatusModal";
import DesassociateKarnottModal from "Components/devices/DesassociateKarnottModal";
import UnlinkDeviceTwoDaysLaterButton from "Components/devices/UnlinkDeviceTwoDaysLaterButton.vue";

const filterFirmwaresByFullVersion = (firmwares, fullVersion) => {
  return firmwares.filter(f => f.fullVersion.indexOf(fullVersion.toLowerCase()) >= 0);
};

let timeout = null;
export default {
  props: ["cluster"],
  components: {
    ChangeStatusModal,
    DesassociateKarnottModal,
    UnlinkDeviceTwoDaysLaterButton
  },
  methods: {
    ...mapActions({
      createMultipleOtaWithDevices: CREATE_MULTIPLE_OTA_WITH_DEVICES_ACTION,
      fetchFirmwaresVersion: FETCH_FIRMWARES_VERSION_ACTION,
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    updateDeviceHasClusterState(device) {
      const { apiClient } = this.$store.getters;
      const { id: deviceId, current_state_with_cluster, associate_date, associate_to_date } = device;
      this.$set(this.changeStateLoading, deviceId, true);
      const associateDate = stringToDate(associate_date);
      const associateToDate = !associate_to_date ? null : stringToDate(associate_to_date);
      associateDate && associateDate.setSeconds(0);
      associateToDate && associateToDate.setSeconds(0);
      const associateDateToString = associateDate && dateToLocaleString(associateDate);
      const associateToDateToString = associateToDate && dateToLocaleString(associateToDate);
      getDeviceHasClusterHistory(apiClient)(deviceId)
        .then(({ data: deviceHasClusters }) => {
          const currentDeviceHasCluster = deviceHasClusters.find(dhc => {
            const { device_instance_id, from_date, to_date } = dhc;
            const fromDate = stringToDate(from_date);
            fromDate.setSeconds(0);
            const toDate = !to_date ? null : stringToDate(to_date);
            toDate && toDate.setSeconds(0);
            const fromDateString = dateToLocaleString(fromDate);
            const toDateString = toDate && dateToLocaleString(toDate);
            const sameDeviceId = device_instance_id === deviceId;
            const sameAssociateDate = associateDateToString === fromDateString;
            const sameAssociateToDate =
              (!associateToDate && (!toDate || toDate.getUTCFullYear() < 1900)) ||
              associateToDateToString === toDateString;
            return sameDeviceId && sameAssociateDate && sameAssociateToDate;
          });
          if (!currentDeviceHasCluster) {
            return Promise.reject("No current device has cluster found");
          }
          const { id } = currentDeviceHasCluster;
          return updateDeviceHasClusterState(apiClient)(id, current_state_with_cluster);
        })
        .then(() => {
          this.$set(this.changeStateLoading, deviceId, false);
          this.setSuccess({
            message: "Le statut du karnott a été mis à jour"
          });
        })
        .catch(e => {
          this.$set(this.changeStateLoading, deviceId, false);
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    getBatteryIcon(external_battery_level, charging_state) {
      if (!external_battery_level) {
        return "battery-off-outline";
      }
      if (charging_state === "CHARGED") {
        return `power-plug-battery`;
      }
      if (charging_state === "CHARGING") {
        return `battery-charging-${Math.ceil(external_battery_level / 10) * 10}`;
      }
      if (external_battery_level > 90) {
        return `battery`;
      }
      return `battery-${Math.ceil(external_battery_level / 10) * 10}`;
    },
    isDeviceOutOfService(device) {
      return isDeviceOutOfService(device);
    },
    isDeviceOk(device) {
      return isDeviceOk(device);
    },
    isDeviceDown(device) {
      return isDeviceDown(device);
    },
    isDeviceCritical(device) {
      return isDeviceCritical(device);
    },
    submitLinkKarnottForm() {
      const { selectedKarnott, cluster, newDeviceState, newAssociateDate } = this;
      const { apiClient } = this.$store.getters;
      Promise.all(selectedKarnott.map(k => linkDeviceToCluster(apiClient)(k.id, cluster.id, newDeviceState)))
        .then(res => {
          if (newAssociateDate === null) {
            return res;
          }
          const rfc3339Date = new Date(newAssociateDate).toISOString();
          return Promise.all(selectedKarnott.map(k => updateAssociateDate(apiClient)(k.id, rfc3339Date)));
        })
        .then(() => {
          this.selectedKarnott = [];
          this.newDeviceState = null;
          this.newAssociateDate = null;
          this.isModalActive = false;
          this.loadDevices();
          this.setSuccess({
            message: "Les karnott ont été liés à l'exploitation"
          });
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    closeModal() {
      this.isModalActive = false;
      this.isOTAModalActive = false;
    },
    showModal() {
      this.isModalActive = true;
    },
    resetData() {
      this.forceOta = false;
      this.toFirmwareVersion = null;
    },
    showOTAModal() {
      if (this.checked.length === 0) {
        alert(`Il faut selectionner au moins un karnott.`);
        return;
      }
      this.isOTAModalActive = true;
    },
    showChangeStatusModal() {
      if (this.checked.length === 0) {
        this.setError({
          message: "Aucun boitier selectionné"
        });
        return;
      }
      this.isChangeStatusModalActive = true;
    },
    showDesassociateModal() {
      if (this.checked.length === 0) {
        this.setError({
          message: "Aucun boitier selectionné"
        });
        return;
      }
      this.isDesassociateModalActive = true;
    },

    closeChangeStatusModalActive() {
      this.isChangeStatusModalActive = false;
    },
    closeDesassociateModal() {
      this.isDesassociateModalActive = false;
    },
    submitForm() {
      if (!this.toFirmwareVersion || !this.toFirmwareVersion.fullVersion) {
        this.setError({
          message: "Veuillez renseigner le nouveau firmware"
        });
        return;
      }
      this.createMultipleOtaWithDevices({
        fromDeviceIds: this.checked.map(d => d.id),
        toFirmwareVersion: {
          major: this.toFirmwareVersion.major,
          minor: this.toFirmwareVersion.minor,
          patch: this.toFirmwareVersion.patch
        },
        forceOTA: this.forceOta
      })
        .then(data => {
          if (this.onSuccess) {
            this.onSuccess();
          }
          this.resetData();
          this.isOTAModalActive = false;
          const count = data === null ? 0 : data.length;
          this.setSuccess({
            message: `${count} OTA ont été créées`
          });
          this.loadDevices();
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue<br />Veuillez contacter le support<br />",
            error: e
          });
        });
    },
    selectNewFirmware(firmware) {
      this.toFirmwareVersion = firmware;
    },
    filterAction(q) {
      clearTimeout(timeout);
      if (this.signal) {
        this.signal.cancel();
        this.signal = null;
      }
      this.deviceSearchResults = [];
      this.isSearchKarnottLoading = false;
      const qLowerCase = q.toLowerCase();
      // because aa & aaa search filter return too many results
      if (q.length < 2 || qLowerCase == "aaa" || qLowerCase == "aa") {
        return;
      }
      timeout = setTimeout(() => {
        if (this.signal) {
          this.signal.cancel();
          this.signal = null;
        }
        const { apiClient } = this.$store.getters;
        const { apiFunc, signal } = callApiWithSignal(searchDevices, apiClient);
        this.signal = signal;
        this.deviceSearchResults = [];
        this.isSearchKarnottLoading = true;
        apiFunc({ serialnumber: q, without_tracks: true })
          .then(({ data }) => {
            this.isSearchKarnottLoading = false;
            // to avoid to have a too big list, limit to 20 result
            if (data && data.length > 20) {
              const dataSlice = [];
              for (let i = 0; i < 20; i++) {
                dataSlice[i] = data[i];
              }
              this.deviceSearchResults = dataSlice;
              return;
            }
            this.deviceSearchResults = data;
          })
          .catch(e => {
            this.isSearchKarnottLoading = false;
            if (e.code === "ERR_CANCELED") {
              return;
            }
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      }, 200);
    },
    unlinkKarnott(device) {
      var message = "Le karnott sera désassocié. Etes vous sur ?";
      if (device.associate_to_date) {
        message = `Le karnott a déjà une date de désassociation au ${this.formatDate(
          device.associate_to_date
        )}. Etes vous sur de la redefinir à maintenant ?`;
      }
      this.$buefy.dialog.confirm({
        message,
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          const { cluster } = this;
          unLinkDeviceToCluster(apiClient)(device.id, cluster.id)
            .then(() => {
              this.setSuccess({
                message: "Le karnott n'est plus lié à l'exploitation"
              });
              this.loadDevices();
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    unlinkSelectedKarnott(devices) {
      const { cluster, setError, setSuccess } = this;
      const { apiClient } = this.$store.getters;

      if (devices.length === 0) {
        setError({
          message: "Aucun boitier selectionné"
        });
        return;
      }
      Promise.all(devices.map(device => unLinkDeviceToCluster(apiClient)(device.id, cluster.id))).then(() => {
        setSuccess({
          message: "Les karnotts ont été désassociés."
        });
        this.loadDevices();
      });
    },
    loadDevices() {
      const { apiClient } = this.$store.getters;
      getClusterDevices(apiClient)(this.cluster.id, { withoutTracks: true })
        .then(({ data }) => {
          this.devices = (data || []).sort(sortById);
          this.checked = [];
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des karnott`,
            error: e
          });
        });
    },
    loadEquipment() {
      const { apiClient } = this.$store.getters;
      return getClusterEquipment(apiClient)(this.cluster.id)
        .then(({ data }) => (this.equipment = (data || []).sort(sortById)))
        .catch(e =>
          this.setError({
            message: `Une erreur est survenue lors du chargement des équipements`,
            error: e
          })
        );
    },
    getEquipmentName(equipmentId) {
      if (equipmentId === 0) {
        return "-";
      }
      const { equipment } = this;
      const findEquipment = equipment.find(e => e.id === equipmentId);

      if (!findEquipment) {
        return "-";
      }
      return findEquipment.label || "-";
    },
    getLink(device) {
      return `/device/${device.id}`;
    },
    formatDate(date) {
      if (typeof date === "string") {
        date = new Date(date);
      }
      return formatDate(date);
    },
    isDateInTheFuture(date) {
      if (typeof date === "string") {
        date = new Date(date);
      }
      return date > new Date();
    }
  },
  mounted() {
    this.fetchFirmwaresVersion().catch(e => {
      this.setError({
        message: "Impossible de récuperer la liste des firmwares",
        error: e
      });
    });
    this.loadEquipment().then(this.loadDevices);
  },
  computed: {
    searchFirmwaresForNew() {
      return filterFirmwaresByFullVersion(
        this.firmwaresVersionToUpdateNotSelected,
        this.searchInput.firmwareNameForNew
      );
    },
    firmwaresVersionToUpdateNotSelected() {
      return this.firmwaresVersion.filter(f => {
        return this.fromFirmwareVersions.find(f2 => f2.fullVersion === f.fullVersion) === undefined;
      });
    },
    linkKarnottWarningMessages() {
      const { selectedKarnott, cluster } = this;
      if (!selectedKarnott || selectedKarnott.length === 0) {
        return null;
      }
      const karnottAlreadyLinkedToThisCluster = selectedKarnott
        .filter(k => k.cluster_id && k.cluster_id === cluster.id)
        .map(k => k.serialnumber);
      const karnottAlreadyLinkedToAnotherCluster = selectedKarnott
        .filter(k => k.cluster_id && k.cluster_id !== cluster.id && k.cluster_id !== 0)
        .map(k => k.serialnumber);
      const karnottWithMountedStatus = selectedKarnott.filter(k => k.status === "MOUNTED").map(k => k.serialnumber);
      const messages = [];
      if (karnottAlreadyLinkedToThisCluster.length > 0) {
        messages.push(
          `Les karnott [${karnottAlreadyLinkedToThisCluster.join(", ")}] sont déjà associé(s) à cette exploitation`
        );
      }
      if (karnottAlreadyLinkedToAnotherCluster.length > 0) {
        messages.push(
          `Les karnott [${karnottAlreadyLinkedToAnotherCluster.join(
            ", "
          )}] sont déjà associé(s) à UNE AUTRE EXPLOITATION`
        );
      }
      if (karnottWithMountedStatus.length > 0) {
        messages.push(
          `Les karnott [${karnottWithMountedStatus.join(
            ", "
          )}] ne pourront pas être lié à l'exploitation car leur status est MOUNTED`
        );
      }
      return messages.length > 0 ? messages : null;
    },
    pastDevices() {
      return this.devices.filter(d => d.associate_to_date && !this.isDateInTheFuture(d.associate_to_date));
    },
    currentDevices() {
      return this.devices.filter(d => !d.associate_to_date || this.isDateInTheFuture(d.associate_to_date));
    },
    ...mapState({ firmwaresVersion: state => state.devices.firmwaresVersion })
  },
  data() {
    return {
      checked: [],
      devices: [],
      deviceSearchResults: [],
      equipment: [],
      forceOta: false,
      fromFirmwareVersions: [],
      isModalActive: false,
      isOTAModalActive: false,
      newDeviceState: null,
      newAssociateDate: null,
      selectedKarnott: [],
      searchInput: {
        firmwareNameForNew: ""
      },
      signal: null,
      toFirmwareVersion: [],
      isSearchKarnottLoading: false,
      isChangeStatusModalActive: false,
      isDesassociateModalActive: false,
      UNDEFINED_DEVICE_STATE_WITH_CLUSTER,
      DEVICE_STATES_WITH_CLUSTER,
      DEVICE_STATES_WITH_CLUSTER_FOR_SELECT,
      changeStateLoading: {}
    };
  }
};
</script>
