<template>
  <b-tabs id="device-conf-tab-item">
    <section class="tab-content">
      <b-field>
        <b-checkbox v-model="isLowPower">Mode tracker (low power)</b-checkbox>
      </b-field>
      <b-field label="Sleep interval (c'est maintenant la distance maximum en mètre en ligne droite) (défaut : 100)">
        <b-input type="number" v-model="sleepInterval" min="0"></b-input>
      </b-field>
      <b-field label="Curve interval (défaut : 5)">
        <b-input type="number" v-model="curveInterval" :max="this.measureInterval" min="0"></b-input>
      </b-field>
      <b-field
        label="Measure interval (c'est maintenant le temps en seconde maximum entre 2 prises de points) (défaut : 20)"
      >
        <b-input type="number" v-model="measureInterval" min="0"></b-input>
      </b-field>
      <b-field label="Beacon interval (défaut :  6000)">
        <b-input type="number" v-model="beaconInterval" min="0"></b-input>
      </b-field>
      <b-field label="GPRS interval (défaut : 900)">
        <b-input type="number" v-model="gprsInterval" min="300"></b-input>
      </b-field>
      <b-field>
        <b-checkbox v-model="forceOta">Force OTA</b-checkbox>
      </b-field>
    </section>
    <div class="tags">
      <div class="tag is-primary is-medium button" @click="updateDeviceConf()">
        Enregistrer
      </div>
      <div class="tag is-success is-medium button" @click="updateDeviceSetDefaultConf()">
        Réglages par défaut
      </div>
    </div>
  </b-tabs>
</template>

<script>
import { uploadDeviceConf } from "Api/device";
export default {
  props: ["device"],
  data() {
    return {
      sleepInterval: this.device.sleep_interval,
      curveInterval: this.device.curve_interval,
      measureInterval: this.device.measure_interval,
      beaconInterval: this.device.beacon_interval,
      gprsInterval: this.device.gprs_interval,
      forceOta: this.device.force_ota,
      isLowPower: this.device.is_low_power
    };
  },
  methods: {
    updateDeviceConf() {
      const payload = {
        id: this.device.id,
        sleep_interval: +this.sleepInterval,
        curve_interval: +this.curveInterval,
        measure_interval: +this.measureInterval,
        beacon_interval: +this.beaconInterval,
        gprs_interval: +this.gprsInterval,
        force_ota: this.forceOta,
        is_low_power: this.isLowPower
      };
      const { apiClient } = this.$store.getters;
      uploadDeviceConf(apiClient)(payload).then(r => {
        if (r.status === 204) {
          this.changesSaved();
        } else {
          this.problemOccurred();
        }
      });
    },
    updateDeviceSetDefaultConf() {
      const payload = {
        id: this.device.id,
        sleep_interval: 100,
        curve_interval: 5,
        measure_interval: 20,
        beacon_interval: 6000,
        gprs_interval: 900,
        force_ota: false
      };
      const { apiClient } = this.$store.getters;
      uploadDeviceConf(apiClient)(payload).then(r => {
        if (r.status === 204) {
          this.sleepInterval = 20;
          this.curveInterval = 5;
          this.measureInterval = 10;
          this.beaconInterval = 6000;
          this.gprsInterval = 900;
          this.forceOta = false;
          this.changesSaved();
        } else {
          this.problemOccurred();
        }
      });
    },
    changesSaved() {
      this.$buefy.snackbar.open({
        duration: 3000,
        message: "Changements enregistrés !",
        position: "is-bottom-right"
      });
    },
    problemOccurred() {
      this.$buefy.snackbar.open({
        duration: 3000,
        type: "is-danger",
        message: "Contactez Laura ! 😏",
        position: "is-bottom-right"
      });
    }
  }
};
</script>
