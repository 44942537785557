<template>
  <div v-if="this.cluster" class="section">
    <section class="hero is-primary">
      <div class="hero-body">
        <h2 class="title" id="title-name">
          <b-tag rounded size="is-large" :type="selectClusterTypeColor(this.cluster.cluster_type)">{{
            this.cluster.cluster_type
          }}</b-tag>
          <b-tag rounded size="is-large" :type="selectClusterStateColor(this.cluster)">{{
            this.selectClusterStateLabel(this.cluster)
          }}</b-tag>
          {{ this.cluster.name }}
          <div class="buttons">
            <a class="button is-info" @click.prevent="showEditModal()">
              <b-icon icon="pencil" />
            </a>
            <a class="button is-danger" @click.prevent="deleteCluster()">
              <b-icon icon="delete" />
            </a>
            <a class="button is-warning" @click.prevent="showMeetingAppointmentModal()">
              <b-icon icon="calendar" />
            </a>
          </div>
        </h2>
        <div class="columns is-multiline is-gapless">
          <div class="column field is-half">
            <label class="label">Adresse</label>
            <span>{{ this.cluster.address ? this.cluster.address : "-" }}</span>
          </div>
          <div class="column field is-one-quarter">
            <label class="label">Partenaires liés</label>
            <div class="tags">
              <b-tag size="is-small" v-if="!clusterPartners"> Aucun </b-tag>
              <b-tag size="is-small" type="is-info" v-for="partner in clusterPartners" :key="partner.partner">
                {{ partner.partner }}
              </b-tag>
            </div>
          </div>
          <div class="column field is-one-quarter">
            <label class="label">Téléphone</label>
            <span>{{ this.cluster.phone ? this.cluster.phone : "-" }}</span>
          </div>
          <div class="column field is-half">
            <label class="label">Détails</label>
            <div :key="detail" v-for="detail in fmtDetails(this.cluster.details)">{{ detail }}</div>
          </div>
          <div class="column field is-one-quarter">
            <label class="label">Application d'origine</label>
            <span>{{ this.cluster.from_application }}</span>
          </div>
          <div class="column field is-one-quarter">
            <label class="label">SIREN</label>
            <span>{{ this.cluster.siren ? this.cluster.siren : "-" }}</span>
          </div>
          <div class="column field is-two-third" id="plugins">
            <div class="message is-info">
              <div class="message-header">Plans</div>
              <div class="message-body">
                <b-message v-if="!hubspotId" type="is-danger">
                  Pour modifier le plan, il faut que l'exploitation ait un `ID hubspot`
                </b-message>
                <b-field>
                  <b-radio
                    :disabled="!hubspotId"
                    @input="() => onChangePlan(plan)"
                    :key="`plan-${plan.name}`"
                    :native-value="plan.name"
                    v-for="plan in plans"
                    v-model="cluster.plan"
                  >
                    {{ PLAN_LABELS[plan.name] || plan.name }}
                  </b-radio>
                </b-field>
              </div>
            </div>
          </div>
          <div class="column field is-one-third">
            <div class="columns is-multiline">
              <div class="column is-full">
                <div class="message is-info">
                  <div class="message-header">Activités</div>
                  <div class="message-body">
                    <b-taginput
                      :allow-new="false"
                      :data="filterActivities"
                      :allow-duplicates="false"
                      :open-on-focus="true"
                      field="label"
                      icon="label"
                      placeholder="Ajouter des activités"
                      autocomplete
                      @add="addActivity"
                      @remove="removeActivity"
                      v-model="cluster.activities"
                      type="is-info"
                    >
                    </b-taginput>
                  </div>
                </div>
              </div>
              <div class="column field is-full">
                <label class="label">ID Hubspot</label>
                <div class="columns">
                  <div class="column is-narrow">
                    <input v-if="editingHubspotId" type="text" class="hubspot_id_input input" v-model="hubspotId" />
                    <a v-else-if="this.hubspotId" target="_blank" :href="hubspotUrl()" class="button is-text">{{
                      this.hubspotId
                    }}</a>
                    <span v-else class="hubspot_id_txt">N/A</span>
                  </div>
                  <div class="column is-narrow">
                    <button
                      v-if="this.editingHubspotId"
                      class="editing_hubspot_id button"
                      @click="updateHubspotId"
                      :disabled="!this.hubspotId || this.hubspotId.length === 0"
                    >
                      Save
                    </button>
                    <button v-else class="save_hubspot_id button" @click="switchOnHubspotIdEditing">Edit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hero-foot">
        <nav class="tabs is-boxed is-fullwidth is-centered">
          <ul>
            <li @click="showTab(HistoricTab)" :class="isTabActive(HistoricTab)">
              <a>Onboarding</a>
            </li>
            <li @click="showTab(PluginsTab)" :class="isTabActive(PluginsTab)">
              <a>Plugins</a>
            </li>
            <li @click="showTab(SupportTeamTab)" :class="isTabActive(SupportTeamTab)">
              <a>Support</a>
            </li>
            <li @click="showTab(AdminsTab)" :class="isTabActive(AdminsTab)">
              <a>Utilisateurs</a>
            </li>
            <li @click="showTab(ParentsClusterTab)" :class="isTabActive(ParentsClusterTab)">
              <a>Ex. parentes</a>
            </li>
            <li @click="showTab(MembersClusterTab)" :class="isTabActive(MembersClusterTab)">
              <a>Ex. membres</a>
            </li>
            <li @click="showTab(EquipmentTab)" :class="isTabActive(EquipmentTab)">
              <a>Matériels</a>
            </li>
            <li @click="showTab(KarnottTab)" :class="isTabActive(KarnottTab)">
              <a>Karnott</a>
            </li>
            <li @click="showTab(BeaconTab)" :class="isTabActive(BeaconTab)">
              <a>KO</a>
            </li>
            <li @click="showTab(BeaconDriverTab)" :class="isTabActive(BeaconDriverTab)">
              <a>KC</a>
            </li>
            <li @click="showTab(ParcelTab)" :class="isTabActive(ParcelTab)">
              <a>Parcelles</a>
            </li>
            <li @click="showTab(JobTab)" :class="isTabActive(JobTab)">
              <a>Jobs</a>
            </li>
            <li @click="showTab(UpsellTab)" :class="isTabActive(UpsellTab)">
              <a>Réassort</a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
    <section>
      <NewClusterStateModal
        :stateName="appointmentState"
        :onClose="() => (appointmentState = null)"
        :cluster="cluster"
        :adminUsers="adminUsers"
        :supportTeamUsers="supportTeamUsers"
        :afterCreate="refreshClusterStateKey"
      />
      <div class="has-background-white">
        <ClusterStateHistory
          v-if="currentTab === HistoricTab"
          :cluster="cluster"
          :adminUsers="adminUsers"
          :supportTeamUsers="supportTeamUsers"
          :onDeleteState="loadCluster"
          :onNewState="loadCluster"
          :key="clusterStateKey"
        />
        <PluginsTab
          v-if="currentTab === PluginsTab"
          :cluster="cluster"
          :clusterPlan="clusterPlan"
          :afterPluginUpdate="() => loadCluster()"
        />
        <ClusterSupportTeam
          v-if="currentTab === SupportTeamTab"
          :cluster="cluster"
          :supportTeamUsers="supportTeamUsers"
          :onUnlink="loadUsers"
          :onLink="loadUsers"
        />
        <ClusterAdmins
          v-if="currentTab === AdminsTab"
          :cluster="cluster"
          :onUnlink="loadUsers"
          :onLink="loadUsers"
          :adminUsers="adminUsers"
        />
        <ParentsCluster v-if="currentTab === ParentsClusterTab" :cluster="cluster" />
        <MembersCluster v-if="currentTab === MembersClusterTab" :cluster="cluster" />
        <EquipmentList v-if="currentTab === EquipmentTab" :cluster="cluster" />
        <KarnottList v-if="currentTab === KarnottTab" :cluster="cluster" />
        <ParcelList v-if="currentTab === ParcelTab" :cluster="cluster" />
        <JobList v-if="currentTab === JobTab" :cluster="cluster" />
        <UpsellList v-if="currentTab === UpsellTab" :cluster="cluster" />
        <BeaconList v-if="currentTab === BeaconTab" :cluster="cluster" :beaconType="BEACON_TYPE_EQUIPMENT" :key="1" />
        <BeaconList
          v-if="currentTab === BeaconDriverTab"
          :cluster="cluster"
          :beaconType="BEACON_TYPE_DRIVER"
          :key="2"
        />
      </div>
    </section>
    <b-modal :active.sync="isEditModalActive">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Modifier les informations de l'exploitations</p>
        </header>
        <section class="modal-card-body">
          <EditForm :cluster="cluster" action="update" :afterSubmit="afterEdit" />
        </section>
      </div>
    </b-modal>
  </div>
</template>

<style>
.modal-card {
  width: 100% !important;
}
.hero h1.title {
  display: inline-block;
  margin-bottom: 0;
}

.hero .buttons {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
#plugins {
  padding-right: 15px !important;
}
</style>

<style scoped>
.hero-body,
.section {
  padding-top: 20px;
}
#title-name {
  padding: 0;
}
#title-name .tag {
  margin: 0 10px 0 0;
}
</style>
<script>
import { mapActions } from "vuex";
import {
  CARE_USER_ROLE,
  SALE_USER_ROLE,
  CLUSTER_ROLES,
  CLUSTER_STATE_CSS_CLASS,
  CLUSTER_STATE_STATE_LABEL,
  BEACON_TYPE_EQUIPMENT,
  BEACON_TYPE_DRIVER,
  APPOINTMENT_PLANNED_STATE,
  PLAN_LABELS
} from "Constants";
import { getClusterById, deleteCluster, getHubspotId, updateHubspotId } from "Api/cluster";
import { getPlans, updatePlan } from "Api/plan";
import { createClusterActivity, deleteClusterActivity } from "Api/clusterActivity";
import { getClusterUsers } from "Api/user";
import { getClusterPartnersByClusterId } from "Api/clusterPartner";
import ClusterStateHistory from "Components/clusters/clusterDetailsComponents/ClusterStateHistory";
import ClusterSupportTeam from "Components/clusters/clusterDetailsComponents/ClusterSupportTeam";
import ClusterAdmins from "Components/clusters/clusterDetailsComponents/ClusterAdmins";
import ParentsCluster from "Components/clusters/clusterDetailsComponents/ParentsCluster";
import MembersCluster from "Components/clusters/clusterDetailsComponents/MembersCluster";
import EquipmentList from "Components/clusters/clusterDetailsComponents/EquipmentList";
import KarnottList from "Components/clusters/clusterDetailsComponents/KarnottList";
import ParcelList from "Components/clusters/clusterDetailsComponents/ParcelList";
import JobList from "Components/clusters/clusterDetailsComponents/JobList";
import UpsellList from "Components/clusters/clusterDetailsComponents/UpsellList";
import BeaconList from "Components/clusters/clusterDetailsComponents/BeaconList";
import PluginsTab from "Components/clusters/clusterDetailsComponents/PluginsTab";
import EditForm from "Components/clusters/Form";
import NewClusterStateModal from "Components/clusters/clusterDetailsComponents/NewClusterStateModal";
import BTag from "buefy/src/components/tag/Tag";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { getClusterTypeColor } from "Utils";

const fmtError = error => message => ({ error, message });
const fmtErrorMessage = entity => `Can't retrieve the ${entity}.<br />Please contact the support.<br />`;

export default {
  components: {
    BTag,
    ClusterStateHistory,
    ClusterSupportTeam,
    ClusterAdmins,
    ParentsCluster,
    MembersCluster,
    EquipmentList,
    KarnottList,
    BeaconList,
    ParcelList,
    JobList,
    UpsellList,
    EditForm,
    NewClusterStateModal,
    PluginsTab
  },
  computed: {
    filterActivities() {
      if (!this.cluster || !this.cluster.activities) {
        return this.activities;
      }
      return this.activities.filter(a => !this.cluster.activities.find(a1 => a1 === a));
    },
    supportTeamUsers() {
      return this.clusterUsers.filter(u => u.role === CARE_USER_ROLE || u.role === SALE_USER_ROLE);
    },
    adminUsers() {
      return this.clusterUsers.filter(u => CLUSTER_ROLES.find(r => r === u.role));
    },
    clusterPlan() {
      const { cluster, plans } = this;
      if (!cluster || !plans) {
        return null;
      }
      return plans.find(p => p.name === cluster.plan);
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    addActivity(activity) {
      const { apiClient } = this.$store.getters;
      createClusterActivity(apiClient)(this.cluster.id, activity).catch(e =>
        this.setError(fmtError(e, "Erreur pour ajouter une activité"))
      );
    },
    removeActivity(activity) {
      const { apiClient } = this.$store.getters;
      deleteClusterActivity(apiClient)(this.cluster.id, activity).catch(e =>
        this.setError(fmtError(e, "Erreur pour supprimer une activité"))
      );
    },
    loadUsers() {
      const { apiClient } = this.$store.getters;
      Promise.all([getClusterUsers(apiClient)(this.cluster.id), getHubspotId(apiClient)(this.cluster.id)])
        .then(res => {
          this.clusterUsers = res[0].data || [];
          this.hubspotId = res[1].data ? res[1].data.hubspot_id : null;
        })
        .catch(e => this.setError(fmtError(e, "Erreur pour récupérer les utilisateurs et/ou informations du cluster")));
    },
    loadCluster() {
      const { apiClient } = this.$store.getters;
      return new Promise((resolve, reject) => {
        getClusterById(apiClient)(this.$route.params.id)
          .then(res => {
            const { data } = res;
            this.cluster = data;
            document.title = this.cluster.name;
            resolve(data);
          })
          .catch(e => {
            this.setError(fmtError(e)(fmtErrorMessage("cluster")));
            reject(e);
          });
      });
    },
    loadClusterPartners() {
      const { apiClient } = this.$store.getters;
      getClusterPartnersByClusterId(apiClient)(this.$route.params.id)
        .then(res => {
          const { data } = res;
          this.clusterPartners = data;
        })
        .catch(e => {
          this.setError(fmtError(e)(fmtErrorMessage("cluster_partner")));
        });
    },
    isTabActive(title) {
      return { "is-active": title === this.currentTab };
    },
    showTab(title) {
      this.currentTab = title;
      this.updateUrl();
    },
    updateUrl() {
      const query = Object.assign({}, this.$route.query);
      query.currentTab = this.currentTab;
      this.$router.push({ path: this.cluster.id.toString(), query }).catch(() => {});
    },
    selectClusterTypeColor: cluster_type => getClusterTypeColor(cluster_type),
    selectClusterStateColor: cluster => CLUSTER_STATE_CSS_CLASS[cluster.current_state],
    selectClusterStateLabel: cluster => CLUSTER_STATE_STATE_LABEL[cluster.current_state] || "Aucun état",
    fmtDetails: details => (details ? details.split("\n") : ["-"]),
    onChangePlan(plan) {
      const { apiClient } = this.$store.getters;
      const { hubspotId } = this;
      if (!hubspotId) {
        this.setError({
          message: "L'exploitation n'a pas d'hubspot ID"
        });
      }
      updatePlan(apiClient)(hubspotId, plan.name)
        .then(() => this.loadCluster())
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue",
            error: e
          });
        });
    },
    showEditModal() {
      this.isEditModalActive = true;
    },
    afterEdit(cluster) {
      this.isEditModalActive = false;
      this.cluster = cluster;
    },
    deleteCluster() {
      this.$buefy.dialog.confirm({
        message: "Etes vous sûr ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          deleteCluster(apiClient)(this.cluster.id)
            .then(() => {
              this.setSuccess({
                message: "L'exploitation a bien été supprimée"
              });
              this.$router.push({ path: "/clusters" }).catch(() => {});
            })
            .catch(e => {
              this.setError({
                message: "Une erreur est survenue",
                error: e
              });
            });
        }
      });
    },
    switchOnHubspotIdEditing() {
      this.editingHubspotId = true;
    },
    switchOffHubspotIdEditing() {
      this.editingHubspotId = false;
    },
    updateHubspotId() {
      const { apiClient } = this.$store.getters;
      this.hubspotId = parseInt(this.hubspotId);
      updateHubspotId(apiClient)(this.cluster.id)(this.hubspotId)
        .then(this.switchOffHubspotIdEditing)
        .catch(e => this.setError(fmtError(e)(fmtErrorMessage("cluster"))));
    },
    hubspotUrl() {
      return `https://app.hubspot.com/contacts/4904226/company/${this.hubspotId}`;
    },
    showMeetingAppointmentModal() {
      this.appointmentState = APPOINTMENT_PLANNED_STATE;
    },
    refreshClusterStateKey() {
      this.clusterStateKey = new Date();
    }
  },
  mounted() {
    if (this.$route.query.currentTab) {
      this.currentTab = this.$route.query.currentTab;
    }

    this.loadCluster().then(() => {
      this.loadUsers();
      this.loadClusterPartners();
    });
    const { apiClient } = this.$store.getters;
    getPlans(apiClient)()
      .then(({ data }) => {
        this.plans = data;
      })
      .catch(e => {
        this.setError({
          message: "Une erreur est survenue pour charger la liste des plans",
          error: e
        });
      });
  },
  data() {
    return {
      activities: [
        "arboriculture",
        "culture",
        "deneigement",
        "elevage",
        "exploitation forestiere",
        "grandes cultures",
        "maraichage",
        "methanisation",
        "ostreiculture",
        "paille",
        "polyactivite",
        "sylviculture",
        "travaux publics",
        "viticulture"
      ],
      clusterUsers: [],
      plans: [],
      hubspotId: null,
      cluster: null,
      currentTab: "KarnottTab",
      SupportTeamTab: "SupportTeamTab",
      AdminsTab: "AdminsTab",
      ParentsClusterTab: "ParentsClusterTab",
      MembersClusterTab: "MembersClusterTab",
      EquipmentTab: "EquipmentTab",
      KarnottTab: "KarnottTab",
      BeaconTab: "BeaconTab",
      BeaconDriverTab: "BeaconDriverTab",
      HistoricTab: "HistoricTab",
      ParcelTab: "ParcelTab",
      JobTab: "JobTab",
      UpsellTab: "UpsellTab",
      PluginsTab: "PluginsTab",
      isEditModalActive: false,
      BEACON_TYPE_EQUIPMENT: BEACON_TYPE_EQUIPMENT,
      BEACON_TYPE_DRIVER: BEACON_TYPE_DRIVER,
      editingHubspotId: false,
      appointmentState: null,
      clusterStateKey: new Date().toString(), // need to have a key on component in order to re-render component after create an appointment
      PLAN_LABELS: PLAN_LABELS,
      clusterPartners: null
    };
  }
};
</script>

<style scoped>
.title {
  padding-left: 0.5em;
}
.hero {
  padding: 1.5em 1em 0;
}
.hero .column {
  padding: 0.5em 1em;
}
.hero .column .label {
  padding-top: 10px;
  padding-bottom: 0.1em;
  margin-bottom: 0;
  color: #d3d3d3;
}
.stat-component {
  position: absolute;
  top: 85px;
  left: calc(100% / 2 - 200px);
  width: 400px;
  text-align: center;
}
</style>
