<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ title }} ({{
              this.beaconClusters.filter(d => formatDate(d.to_date) === "-" && beaconsMap[d.beacon_uuid]).length
            }})
          </h1>
          <div class="buttons">
            <a class="button is-primary" @click.prevent="showModal()"> Lier des Beacons </a>
            <b-button type="is-warning" @click.prevent="showDesassociateModal()">
              Désassocier les Beacons sélectionnés
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <b-table
        :data="beaconClusters.filter(d => formatDate(d.to_date) === '-' && beaconsMap[d.beacon_uuid])"
        :checked-rows.sync="checked"
        checkable
      >
        <b-table-column field="id" label="Beacon UUID" v-slot="props">
          <router-link target="_blank" :to="`/beacon/${props.row.beacon_uuid}`" class="button is-text">
            {{ props.row.beacon_uuid }}
          </router-link>
        </b-table-column>
        <b-table-column field="associate_date" label="Date de début d'association" v-slot="props">
          {{ formatDate(props.row.from_date) }}
        </b-table-column>
        <b-table-column field="associate_date" label="Date de fin d'association" v-slot="props">
          {{ formatDate(props.row.to_date) }}
        </b-table-column>
        <b-table-column field="last_info_date" label="Date de dernière connexion" v-slot="props">
          {{ formatDate(props.row.beacon.location_date) }}
        </b-table-column>
        <b-table-column
          :visible="beaconType !== BEACON_TYPE_DRIVER"
          field="equipment_id"
          label="Matériel courant"
          v-slot="props"
        >
          {{ (props.row.equipment || {}).label || "-" }}
        </b-table-column>
        <b-table-column v-slot="props" field="state" label="Statut de liaison">
          <b-select
            :loading="changeStateLoading[props.row.id]"
            @input="updateBeaconClusterState(props.row)"
            v-model="props.row.state"
          >
            <option
              v-bind:key="option"
              v-for="option in DEVICE_STATES_WITH_CLUSTER"
              :value="option"
              :disabled="option === UNDEFINED_DEVICE_STATE_WITH_CLUSTER"
            >
              {{ fmtDeviceStateWithCluster(option) }}
            </option>
          </b-select>
        </b-table-column>
        <b-table-column field="actions" label="Action" v-slot="props">
          <div class="buttons has-addons">
            <a class="button is-danger" @click.prevent="unlinkBeacon(props.row)">
              <b-icon icon="link-off" size="is-small"></b-icon>
            </a>
          </div>
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info"> Aucun Beacon </b-message>
        </template>
      </b-table>
    </div>

    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Liste des Beacons qui ont été associés par le passé ({{
              this.beaconClusters.filter(d => formatDate(d.to_date) !== "-").length
            }})
          </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <b-table :data="beaconClusters.filter(d => formatDate(d.to_date) !== '-')">
        <b-table-column field="id" label="Beacon UUID" v-slot="props"> {{ props.row.beacon_uuid }} </b-table-column>
        <b-table-column field="associate_date" label="Date de début d'association" v-slot="props">
          {{ formatDate(props.row.from_date) }}
        </b-table-column>
        <b-table-column field="associate_date" label="Date de fin d'association" v-slot="props">
          {{ formatDate(props.row.to_date) }}
        </b-table-column>
        <b-table-column v-slot="props" field="state" label="Statut de liaison">
          <b-select
            :loading="changeStateLoading[props.row.id]"
            @input="updateDeviceHasClusterState(props.row)"
            v-model="props.row.state"
          >
            <option
              v-bind:key="option"
              v-for="option in DEVICE_STATES_WITH_CLUSTER"
              :value="option"
              :disabled="option === UNDEFINED_DEVICE_STATE_WITH_CLUSTER"
            >
              {{ fmtDeviceStateWithCluster(option) }}
            </option>
          </b-select>
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info"> Aucun Beacon </b-message>
        </template>
      </b-table>
    </div>

    <b-modal :active="isModalActive" :on-cancel="closeModal">
      <form @submit.prevent="submitLinkBeaconForm">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Selectionner les Beacons</p>
          </header>
          <section class="modal-card-body">
            <b-message type="is-warning" v-if="newBeaconAlreadyLinked">
              Attention, l'un des beacons séléctionné est déjà associé à une exploitation. Cette nouvelle association
              arrêtera l'association avec l'exploitation courante
            </b-message>
            <b-field label="Rechercher les Beacons">
              <b-taginput
                :allow-new="false"
                :data="beaconSearchResults"
                :allow-duplicates="false"
                field="readable_uuid"
                value="uuid"
                icon="label"
                placeholder="1111-1111"
                autocomplete
                v-model="selectedBeacon"
                @typing="filterAction"
              >
              </b-taginput>
            </b-field>
            <b-field label="Statut de la liaison">
              <b-select v-model="newDeviceState">
                <option v-bind:key="option" v-for="option in DEVICE_STATES_WITH_CLUSTER_FOR_SELECT" :value="option">
                  {{ fmtDeviceStateWithCluster(option) }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Date d'association">
              <b-input type="date" v-model="newAssociateDate" />
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
          <button class="button is-info">Associer</button>
        </footer>
      </form>
    </b-modal>
    <DesassociateBeaconModal
      :beacons="checked"
      :onClose="closeDesassociateModal"
      :isActive="isDesassociateModalActive"
      :unlinkBeacons="unlinkSelectedBeacons"
    />
  </div>
</template>

<style scoped>
.hero h1.title {
  display: inline-block;
  margin-bottom: 0;
}

.hero .buttons {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.modal-card {
  min-height: 250px;
  width: 100%;
}
.health-td .tag {
  width: 100%;
}
</style>
<script>
import { mapActions } from "vuex";
import { getBeacons, updateBeaconAssociateDate } from "Api/beacon";
import {
  linkBeaconToCluster,
  getBeaconClustersByClusterID,
  unLinkBeaconToCluster,
  updateBeaconClusterState
} from "Api/beaconCluster";
import { search } from "Api/search";
import { getEquipmentByIds } from "Api/equipment";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { callApiWithSignal, formatDate } from "Utils";
import {
  BEACON_TYPE_DRIVER,
  UNDEFINED_DEVICE_STATE_WITH_CLUSTER,
  DEVICE_STATES_WITH_CLUSTER,
  DEVICE_STATES_WITH_CLUSTER_FOR_SELECT,
  DEVICE_STATES_WITH_CLUSTER_LABEL
} from "Constants";
import DesassociateBeaconModal from "Components/devices/DesassociateBeaconModal";

var timeout = null;

export default {
  props: ["cluster", "beaconType"],
  components: {
    DesassociateBeaconModal
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    updateBeaconClusterState(bc) {
      const { apiClient } = this.$store.getters;
      const { id, state } = bc;
      this.$set(this.changeStateLoading, id, true);
      updateBeaconClusterState(apiClient)(id, state)
        .then(() => {
          this.$set(this.changeStateLoading, id, false);
          this.setSuccess({
            message: "Le statut du beacon a été mis à jour"
          });
        })
        .catch(e => {
          this.$set(this.changeStateLoading, id, false);
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    submitLinkBeaconForm() {
      const { selectedBeacon, cluster, newAssociateDate, newDeviceState } = this;
      const { apiClient } = this.$store.getters;
      Promise.all(selectedBeacon.map(b => linkBeaconToCluster(apiClient)(b.uuid, cluster.id, newDeviceState)))
        .then(res => {
          if (newAssociateDate === null) {
            return res;
          }
          const rfc3339Date = new Date(newAssociateDate).toISOString();
          return Promise.all(selectedBeacon.map(b => updateBeaconAssociateDate(apiClient)(b.uuid, rfc3339Date)));
        })
        .then(() => {
          this.selectedBeacon = [];
          this.newDeviceState = null;
          this.newAssociateDate = null;
          this.isModalActive = false;
          this.loadBeaconClusters();
          this.setSuccess({
            message: "Les beacons ont été liés à l'exploitation"
          });
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    closeModal() {
      this.isModalActive = false;
    },
    showModal() {
      this.isModalActive = true;
    },
    showDesassociateModal() {
      if (this.checked.length === 0) {
        this.setError({
          message: "Aucun beacon selectionné"
        });
        return;
      }
      this.isDesassociateModalActive = true;
    },
    closeDesassociateModal() {
      this.isDesassociateModalActive = false;
    },
    unlinkBeacon(beaconCluster) {
      this.$buefy.dialog.confirm({
        message: "Etes vous sur ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          unLinkBeaconToCluster(apiClient)(beaconCluster.beacon_uuid)
            .then(() => {
              this.setSuccess({
                message: "Le beacon n'est plus lié à l'exploitation"
              });
              this.loadBeaconClusters();
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    unlinkSelectedBeacons(beacons) {
      const { apiClient } = this.$store.getters;
      const { setError, setSuccess } = this;
      if (beacons.length === 0) {
        setError({
          message: "Aucun beacon selectionné"
        });
        return;
      }
      Promise.all(beacons.map(beacon => unLinkBeaconToCluster(apiClient)(beacon.beacon_uuid))).then(() => {
        setSuccess({
          message: "Les beacons ont été désassociés."
        });
        this.loadBeaconClusters();
      });
    },
    loadBeaconClusters() {
      const { apiClient } = this.$store.getters;
      getBeaconClustersByClusterID(apiClient)(this.cluster.id)
        .then(({ data }) => {
          this.beaconClustersCache = (data || []).sort((a, b) => {
            return a.from_date < b.from_date ? 1 : -1;
          });
          if (data) {
            const { apiClient } = this.$store.getters;
            getBeacons(apiClient)({
              type: this.beaconType,
              uuids: this.beaconClustersCache.map(u => u.beacon_uuid),
              withoutTracks: true
            })
              .then(({ data }) => {
                this.beaconsCache = data;
                if (this.beaconType === BEACON_TYPE_DRIVER) return null;
                const equipmentsId = this.beaconsCache
                  .map(beacon => beacon.current_equipment_instance_id)
                  .filter(e => e);
                if (equipmentsId.length === 0) return null;
                return getEquipmentByIds(apiClient)(equipmentsId);
              })
              .then(response => {
                if (response === null) return;
                const { data } = response;
                const equipmentsCache = {};
                data.forEach(equipment => (equipmentsCache[equipment.id] = equipment));
                this.equipmentsCache = equipmentsCache;
              });
          }
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des beacons`,
            error: e
          });
        });
    },
    getLink(beacon) {
      return `/beacon/${beacon.uuid}`;
    },
    formatDate(date) {
      if (typeof date === "string") {
        date = new Date(date);
      }
      return formatDate(date);
    },
    filterAction(searchUuid) {
      clearTimeout(timeout);
      if (this.signal) {
        this.signal.cancel();
      }
      const qLowerCase = searchUuid.toLowerCase();
      // because aa & aaa search filter return too many results
      if (qLowerCase.length < 2 || qLowerCase == "aaa" || qLowerCase == "aa") {
        return;
      }
      timeout = setTimeout(() => {
        const { apiClient } = this.$store.getters;
        const { apiFunc, signal } = callApiWithSignal(search, apiClient);
        this.signal = signal;
        apiFunc({ q: searchUuid })
          .then(({ data }) => {
            this.beaconSearchResults = data.beacons.filter(b => b.type === this.beaconType);
          })
          .catch(e => {
            if (e.code !== "ERR_CANCELED") {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            }
          });
      }, 300);
    }
  },
  watch: {
    beaconType() {
      this.loadBeaconClusters();
    },
    selectedBeacon() {
      this.newBeaconAlreadyLinked = false;
      this.selectedBeacon.forEach(b => {
        if (b.cluster_id && b.cluster_id !== 0) {
          this.newBeaconAlreadyLinked = true;
        }
      });
    }
  },
  computed: {
    title() {
      const { beaconType } = this;
      if (beaconType === BEACON_TYPE_DRIVER) {
        return `Liste des Beacons chauffeurs`;
      } else {
        return `Liste des Beacons équipements`;
      }
    },
    beacons() {
      const { beaconsCache, beaconType } = this;
      return beaconsCache.filter(beacon => beacon.type === beaconType);
    },
    beaconsMap() {
      const { beacons } = this;
      return beacons.reduce((obj, beacon) => ({ ...obj, [beacon.uuid]: beacon }), {});
    },
    beaconClusters() {
      const { beaconClustersCache, beaconsMap, equipmentsCache } = this;
      return beaconClustersCache
        .filter(({ beacon_uuid }) => beaconsMap[beacon_uuid])
        .map(bc => {
          const beacon = beaconsMap[bc.beacon_uuid];
          return { ...bc, beacon, equipment: equipmentsCache[beacon.current_equipment_instance_id] };
        });
    }
  },
  mounted() {
    this.loadBeaconClusters();
  },
  data() {
    return {
      beaconClustersCache: [],
      beaconsCache: [],
      beaconSearchResults: [],
      selectedBeacon: [],
      isModalActive: false,
      newDeviceState: null,
      newAssociateDate: null,
      newBeaconAlreadyLinked: false,
      equipmentsCache: {},
      BEACON_TYPE_DRIVER,
      checked: [],
      isDesassociateModalActive: false,
      DEVICE_STATES_WITH_CLUSTER,
      DEVICE_STATES_WITH_CLUSTER_FOR_SELECT,
      UNDEFINED_DEVICE_STATE_WITH_CLUSTER,
      changeStateLoading: {}
    };
  }
};
</script>
