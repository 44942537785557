<template>
  <LinkClusterToEntityModal
    title="Lier une exploitation au Karnott"
    successMessage="L'exploitation a bien été liée au Karnott"
    errorMessage="Impossible de lier le cluster au Karnott <br />"
    :linkAction="onSelectCluster"
    :closeModalAction="closeModalAction"
    :isActive="isActive"
  />
</template>

<script>
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { linkDeviceToCluster } from "Api/deviceHasCluster";
import { mapActions } from "vuex";
import LinkClusterToEntityModal from "Components/clusters/LinkClusterToEntityModal";

export default {
  props: ["device", "isActive", "closeModalAction"],
  methods: {
    onSelectCluster(clusterId, state) {
      if (clusterId === undefined) {
        return;
      }
      const { apiClient } = this.$store.getters;
      return linkDeviceToCluster(apiClient)(this.device.id, clusterId, state);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  },
  components: {
    LinkClusterToEntityModal
  }
};
</script>
