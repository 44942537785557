<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Liste des plugins</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="message is-info">
        <div class="message-header">Plugins du plan</div>
        <div class="message-body">
          <div class="columns is-multiline">
            <div class="column is-one-third" :key="`plan-plugin-${plugin.option}`" v-for="plugin in clusterPlanPlugins">
              <b-switch @input="v => onChangePlugin(plugin.option, v)" type="is-success" v-model="plugin.activated">
                {{ PLUGIN_LABELS[plugin.option] }}
              </b-switch>
            </div>
          </div>
        </div>
        <div class="message-header">Plugins supplémentaires</div>
        <div class="message-body">
          <div class="columns is-multiline">
            <div class="column is-one-third" :key="`custom-plugin-${plugin.option}`" v-for="plugin in customPlugins">
              <b-switch @input="v => onChangePlugin(plugin.option, v)" type="is-success" v-model="plugin.activated">
                {{ PLUGIN_LABELS[plugin.option] }}
              </b-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { createClusterPlugin } from "Api/clusterPlugin";
import { getPlugins } from "Api/plugin";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import {
  DO_NOT_IGNORE_PARCELS_PLUGIN,
  PHYTO_PLUGIN,
  RPG_ILOT_PLUGIN,
  LOCK_GEOLOCATION_PLUGIN,
  DISABLE_DRIVER_GEOLOC_PLUGIN,
  PLUGIN_LABELS
} from "Constants";

export default {
  props: ["cluster", "clusterPlan", "afterPluginUpdate"],
  computed: {
    clusterPlanPlugins() {
      const { clusterPlan, cluster, PLUGIN_LABELS } = this;
      if (!clusterPlan) {
        return [];
      }
      // if no label, it means the plugin is not activated
      return clusterPlan.plugins
        .filter(p => PLUGIN_LABELS[p])
        .map(plugin => {
          return cluster.plugins.find(cp => cp.option === plugin) || { option: plugin, activated: false };
        });
    },
    customPlugins() {
      const { cluster, clusterPlanPlugins, plugins, PLUGIN_LABELS } = this;
      if (!clusterPlanPlugins || !plugins) {
        return plugins;
      }
      let p = plugins
        .filter(plugin => {
          return PLUGIN_LABELS[plugin.name] && !clusterPlanPlugins.find(cp => cp.option === plugin.name);
        })
        .map(plugin => {
          return (
            (cluster.plugins && cluster.plugins.find(cp => cp.option === plugin.name)) || {
              option: plugin.name,
              activated: false
            }
          );
        });

      // Un plugin ne fait partie d'aucun plan
      let doNotIgnoreParcel = cluster.plugins.find(cp => cp.option === DO_NOT_IGNORE_PARCELS_PLUGIN);
      p.push({
        option: DO_NOT_IGNORE_PARCELS_PLUGIN,
        activated: doNotIgnoreParcel ? doNotIgnoreParcel.activated : false
      });
      let phyto = cluster.plugins.find(cp => cp.option === PHYTO_PLUGIN);
      p.push({
        option: PHYTO_PLUGIN,
        activated: phyto ? phyto.activated : false
      });
      let rpg_ilot_plugin = cluster.plugins.find(cp => cp.option === RPG_ILOT_PLUGIN);
      p.push({
        option: RPG_ILOT_PLUGIN,
        activated: rpg_ilot_plugin ? rpg_ilot_plugin.activated : false
      });
      let lock_geolocation_plugin = cluster.plugins.find(cp => cp.option === LOCK_GEOLOCATION_PLUGIN);
      p.push({
        option: LOCK_GEOLOCATION_PLUGIN,
        activated: lock_geolocation_plugin ? lock_geolocation_plugin.activated : false
      });
      let disable_driver_geoloc = cluster.plugins.find(cp => cp.option === DISABLE_DRIVER_GEOLOC_PLUGIN);
      p.push({
        option: DISABLE_DRIVER_GEOLOC_PLUGIN,
        activated: disable_driver_geoloc ? disable_driver_geoloc.activated : false
      });
      return p;
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    onChangePlugin(plugin, activated) {
      const { cluster } = this;
      const { apiClient } = this.$store.getters;
      const data = {
        option: plugin,
        activated
      };
      createClusterPlugin(apiClient)(cluster.id, data)
        .then(({ data }) => this.afterPluginUpdate && this.afterPluginUpdate(data))
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue",
            error: e
          });
        });
    },
    isPluginActivated(plugin) {
      const { cluster } = this;
      if (!cluster.plugins) {
        return false;
      }
      const clusterPlugin = cluster.plugins.find(p => p.option === plugin);
      if (!clusterPlugin) {
        return false;
      }
      return clusterPlugin.activated;
    }
  },
  mounted() {
    const { apiClient } = this.$store.getters;
    getPlugins(apiClient)()
      .then(({ data }) => {
        this.plugins = data;
      })
      .catch(e => {
        this.setError({
          message: "Une erreur est survenue pour charger la liste des plugins",
          error: e
        });
      });
  },
  data() {
    return {
      plugins: [],
      PLUGIN_LABELS
    };
  }
};
</script>
