<template>
  <div v-if="this.beacon" class="section">
    <section class="hero is-primary">
      <h1 class="title">
        <span :class="`tag ${getBeaconTypeCssClassName(this.beacon.type)}`">
          {{ this.beacon.type }}
        </span>
        Beacon {{ this.beacon.uuid }}
      </h1>
      <div class="hero-body">
        <div class="columns is-vcentered is-multiline">
          <div class="column field is-one-third" v-if="currentUser && !currentUser.isHardware()">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Exploitation :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="beacon-current-cluster">
                  <div class="tags has-addons">
                    <router-link
                      :to="'/cluster/' + this.beacon.cluster_id"
                      class="tag current-cluster-name"
                      target="_blank"
                    >
                      {{ this.currentClusterName }}
                    </router-link>
                    <div class="tag is-success button" @click="() => (showLinkBeaconToClusterModal = true)">
                      Modifier
                    </div>
                    <div
                      v-if="beacon.cluster_id && beacon.cluster_id > 0"
                      class="tag is-warning button"
                      @click="() => (showUpdateAssociateDateModal = true)"
                    >
                      Modifier la date d'association
                    </div>

                    <div
                      v-if="beacon.cluster_id && beacon.cluster_id > 0"
                      class="tag button is-danger"
                      @click.prevent="unlinkBeacon()"
                    >
                      <b-icon icon="link-off" size="is-small"></b-icon>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div class="columns is-vcentered" v-if="!!currentBeaconCluster?.cluster_id">
              <div class="column is-narrow">
                <label class="label"> Statut de liaison avec l'exploitation :</label>
                <b-select
                  :loading="changeStateLoading"
                  @input="updateBeaconClusterState()"
                  v-model="currentBeaconCluster.state"
                >
                  <option v-bind:key="option" v-for="option in DEVICE_STATES_WITH_CLUSTER" :value="option" :disabled="option === UNDEFINED_DEVICE_STATE_WITH_CLUSTER">
                    {{ fmtDeviceStateWithCluster(option) }}
                  </option>
                </b-select>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Date dernière information :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="device-status">{{
                  this.beacon.lastinfo_date ? new Date(Date.parse(this.beacon.lastinfo_date)).toLocaleString() : "-"
                }}</span>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Date dernière position connue :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="device-status">{{
                  this.beacon.location_date ? new Date(Date.parse(this.beacon.location_date)).toLocaleString() : "-"
                }}</span>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <label class="label">Statut :</label>
              </div>
              <div class="column" style="padding-left: 0">
                <span class="device-status">{{ this.beacon.status }}</span>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="columns is-vcentered">
              <div id="map-container">
                <b-loading :is-full-page="false"> </b-loading>
                <div id="map-last-location"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hero-foot">
        <nav class="tabs is-boxed is-fullwidth">
          <div class="container">
            <ul>
              <li
                :class="{ 'is-active': activeTab === ClustersHistoryTab }"
                v-if="currentUser && !currentUser.isHardware()"
                @click.prevent="() => (activeTab = ClustersHistoryTab)"
              >
                <a>Historique des exploitations</a>
              </li>
              <li
                :class="{ 'is-active': activeTab === EquipmentHistoryTab }"
                v-if="currentUser && !currentUser.isHardware() && beacon.type === BEACON_TYPE_EQUIPMENT"
                @click.prevent="() => (activeTab = EquipmentHistoryTab)"
              >
                <a>Historique des équipements</a>
              </li>
              <li
                :class="{ 'is-active': activeTab === UsersHistoryTab }"
                v-if="currentUser && !currentUser.isHardware() && beacon.type === BEACON_TYPE_DRIVER"
                @click.prevent="() => (activeTab = UsersHistoryTab)"
              >
                <a>Historique des chauffeurs</a>
              </li>
              <li
                :class="{ 'is-active': activeTab === EventPointsTab }"
                v-if="currentUser && !currentUser.isHardware()"
                @click.prevent="() => (activeTab = EventPointsTab)"
              >
                <a>Carte des points GPS du beacon</a>
              </li>
              <li
                :class="{ 'is-active': activeTab === SessionsTab }"
                v-if="currentUser && !currentUser.isHardware()"
                @click.prevent="() => (activeTab = SessionsTab)"
              >
                <a>Liste des sessions</a>
              </li>
              <li
                :class="{ 'is-active': activeTab === BeaconDataTab }"
                v-if="currentUser && !currentUser.isHardware()"
                @click.prevent="() => (activeTab = BeaconDataTab)"
              >
                <a>Liste des captations</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>

    <section>
      <div class="has-background-white">
        <UpdateBeaconAssociateDateModal
          :beacon="beacon"
          :currentDate="beacon.associate_date"
          :isActive="showUpdateAssociateDateModal"
          :closeModalAction="closeUpdateBeaconAssociateDateModal"
        />
        <LinkBeaconToClusterModal
          :beacon="beacon"
          :isActive="showLinkBeaconToClusterModal"
          :closeModalAction="closeLinkBeaconToClusterModal"
        />

        <BeaconClustersHistoryTabItem
          :beacon="beacon"
          v-if="activeTab === ClustersHistoryTab && currentUser && !currentUser.isHardware()"
          :onCurrentBeaconClusterStateChange="reloadCurrentBeaonCluster"
        />
        <EquipmentHistoryTabItem
          :beacon="beacon"
          v-if="activeTab === EquipmentHistoryTab && currentUser && !currentUser.isHardware()"
          :clusterEquipments="clusterEquipments"
        />
        <UsersHistoryTabItem
          :beacon="beacon"
          v-if="activeTab === UsersHistoryTab && currentUser && !currentUser.isHardware()"
          :clusterUsers="clusterUsers"
        />
        <DeviceEventPointsTabItem
          :beacon="beacon"
          v-if="activeTab === EventPointsTab && currentUser && !currentUser.isHardware()"
        />
        <SessionsTabItem
          :beacon="beacon"
          v-if="activeTab === SessionsTab && currentUser && !currentUser.isHardware()"
        />
        <BeaconDataTabItem
          :beacon="beacon"
          v-if="activeTab === BeaconDataTab && currentUser && !currentUser.isHardware()"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { getClusterById } from "Api/cluster";
import { getClusterEquipment } from "Api/equipment";
import { getClusterUsers } from "Api/user";
import { getBeaconClustersByBeaconUuid, unLinkBeaconToCluster, updateBeaconClusterState } from "Api/beaconCluster";
import { getBeaconByUuid } from "Api/beacon";
import { GET_USER_ME_ACTION } from "Stores/users";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import BeaconClustersHistoryTabItem from "Components/beacons/BeaconClustersHistoryTabItem";
import EquipmentHistoryTabItem from "Components/beacons/EquipmentHistoryTabItem";
import LinkBeaconToClusterModal from "Components/beacons/LinkBeaconToClusterModal";
import UpdateBeaconAssociateDateModal from "Components/beacons/UpdateBeaconAssociateDateModal";
import DeviceEventPointsTabItem from "Components/devices/DeviceEventPointsTabItem";
import SessionsTabItem from "Components/beacons/SessionsTabItem";
import BeaconDataTabItem from "Components/beacons/BeaconDataTabItem";
import UsersHistoryTabItem from "Components/beacons/UsersHistoryTabItem";
import { getBeaconTypeCssClassName } from "Utils";
import { BEACON_TYPE_EQUIPMENT, BEACON_TYPE_DRIVER, UNDEFINED_DEVICE_STATE_WITH_CLUSTER, DEVICE_STATES_WITH_CLUSTER, DEVICE_STATES_WITH_CLUSTER_LABEL } from "Constants";

import { mapActions, mapGetters } from "vuex";

const ClustersHistoryTab = "ClustersHistoryTab";
const EquipmentHistoryTab = "EquipmentHistoryTab";
const EventPointsTab = "EventPointsTab";
const SessionsTab = "SessionsTab";
const BeaconDataTab = "BeaconDataTab";
const UsersHistoryTab = "UsersHistoryTab";

const fmtError = error => message => ({ error, message });
const fmtErrorMessage = entity => `Can't retrieve the ${entity}.<br />Please contact the support.<br />`;
/// Below line disables no-undef google Maps related warnings
/// (because Google maps is loaded in a script tag rather than
/// in the bundle.).
/* global google */
export default {
  mounted() {
    const { apiClient } = this.$store.getters;
    const getBeacon = getBeaconByUuid(apiClient);
    const { uuid } = this?.$route?.params || {};
    Promise.all([this.getCurrentUser(), getBeacon(uuid), this.reloadCurrentBeaonCluster()])
      .then(res => {
        this.currentUser = res[0];
        const { data } = res[1];
        this.beacon = data;
        document.title = data.uuid;
        const { cluster_id } = this.beacon;
        if (this.currentUser.isHardware() || cluster_id === undefined || cluster_id === 0) {
          return { data: {} };
        }
        if (data.associate_date && data.associate_date !== "") {
          this.beacon.associate_date = new Date(data.associate_date);
        }
        return getClusterById(apiClient)(cluster_id);
      })
      .then(({ data }) => (this.currentCluster = data))
      .catch(e => this.setError(fmtError(e)(fmtErrorMessage("beacon"))));
  },
  updated() {
    this.initializeMap();
  },
  data() {
    return {
      currentUser: null,
      beacon: null,
      currentCluster: {},
      showLinkBeaconToClusterModal: false,
      showUpdateAssociateDateModal: false,
      ClustersHistoryTab: ClustersHistoryTab,
      EquipmentHistoryTab: EquipmentHistoryTab,
      EventPointsTab: EventPointsTab,
      SessionsTab: SessionsTab,
      BeaconDataTab: BeaconDataTab,
      UsersHistoryTab: UsersHistoryTab,
      clusterEquipments: [],
      activeTab: EventPointsTab,
      map: null,
      BEACON_TYPE_EQUIPMENT,
      BEACON_TYPE_DRIVER,
      clusterUsers: [],
      DEVICE_STATES_WITH_CLUSTER,
      UNDEFINED_DEVICE_STATE_WITH_CLUSTER,
      changeStateLoading: false,
      currentBeaconCluster: {}
    };
  },
  computed: {
    currentClusterName() {
      const { currentCluster } = this;
      if (currentCluster === undefined) {
        return "-";
      }
      const { name } = currentCluster;
      if (name === undefined) {
        return "-";
      }
      return name;
    }
  },
  methods: {
    updateBeaconClusterState() {
      const { apiClient } = this.$store.getters;
      const { id, state } = this.currentBeaconCluster;
      this.changeStateLoading = true;
      updateBeaconClusterState(apiClient)(id, state)
        .then(() => {
          this.changeStateLoading = false;
          this.beacon = {
            ...this.beacon,
            ...{ current_state_with_cluster: state }
          };
          this.setSuccess({
            message: "Le statut du beacon a été mis à jour"
          });
        })
        .catch(e => {
          this.changeStateLoading = false;
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    getBeaconTypeCssClassName(type) {
      return getBeaconTypeCssClassName(type);
    },
    initializeMap() {
      if (this.beacon.location_latitude) {
        this.map = new google.maps.Map(document.getElementById("map-last-location"), {
          zoom: 16,
          mapTypeId: google.maps.MapTypeId.HYBRID,
          tilt: 0,
          rotateControl: false,
          disableDefaultUI: true
        });
        var myLatLng = { lat: this.beacon.location_latitude, lng: this.beacon.location_longitude };
        var marker = new google.maps.Marker({
          position: myLatLng,
          map: this.map,
          title: "Hello World!"
        });
        marker.setMap(this.map);
        this.map.setCenter(myLatLng);
      }
    },
    closeUpdateBeaconAssociateDateModal(newDate) {
      if (newDate) {
        this.beacon = {
          ...this.beacon,
          ...{ associate_date: newDate }
        };
      }
      this.showUpdateAssociateDateModal = false;
    },
    reloadCurrentBeaonCluster() {
      const { apiClient } = this.$store.getters;
      const getBeaconClusters = getBeaconClustersByBeaconUuid(apiClient);
      const { uuid } = this?.$route?.params || {};
      return getBeaconClusters(uuid)
        .then(response => {
          const { data: beaconClusters } = response;
          if (beaconClusters?.length) {
            this.currentBeaconCluster = beaconClusters.find(bc => !bc.to_date);
          }
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue en essayant de definir l'exploitation courante`,
            error: e
          });
        });
    },
    closeLinkBeaconToClusterModal(cluster) {
      if (cluster && cluster.id !== undefined) {
        this.setNewCurrentCluster(cluster);
        this.reloadCurrentBeaonCluster();
      }
      this.showLinkBeaconToClusterModal = false;
    },
    setNewCurrentCluster(cluster) {
      this.currentCluster = cluster;
      this.beacon = {
        ...this.beacon,
        ...{ cluster_id: cluster.id }
      };
    },
    unlinkBeacon() {
      this.$buefy.dialog.confirm({
        message: "Etes vous sur ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          const { beacon } = this;
          unLinkBeaconToCluster(apiClient)(beacon.uuid)
            .then(() => {
              this.currentBeaconCluster = {};
              this.setSuccess({
                message: "Le beacon n'est plus liée à l'exploitation"
              });
              this.beacon = {
                ...this.beacon,
                ...{ cluster_id: 0 }
              };
              this.currentCluster = {};
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    ...mapActions({
      getCurrentUser: GET_USER_ME_ACTION,
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    ...mapGetters(["getHostname"])
  },
  watch: {
    currentCluster() {
      const { currentCluster, beacon } = this;
      if (!currentCluster || !currentCluster.id) {
        return;
      }
      const { apiClient } = this.$store.getters;
      if (beacon.type === BEACON_TYPE_EQUIPMENT) {
        getClusterEquipment(apiClient)(currentCluster.id)
          .then(({ data }) => {
            this.clusterEquipments = data;
          })
          .catch(e => {
            this.setError({
              message: `Une erreur est survenue pour récupérer les équipements de l'exploitation`,
              error: e
            });
          });
      }

      if (beacon.type === BEACON_TYPE_DRIVER) {
        getClusterUsers(apiClient)(currentCluster.id)
          .then(({ data }) => {
            this.clusterUsers = data;
          })
          .catch(e => {
            this.setError({
              message: `Une erreur est survenue pour récupérer les utilisateurs de l'exploitation`,
              error: e
            });
          });
      }
    }
  },
  components: {
    BeaconClustersHistoryTabItem,
    EquipmentHistoryTabItem,
    LinkBeaconToClusterModal,
    DeviceEventPointsTabItem,
    UpdateBeaconAssociateDateModal,
    SessionsTabItem,
    BeaconDataTabItem,
    UsersHistoryTabItem
  }
};
</script>

<style scoped>
.tabs {
  font-size: 14px !important;
}
.title {
  padding-left: 0.5em;
}
.hero {
  padding: 1.5em 1em 0;
}
.hero .column {
  padding: 0.5em 1em;
}
.hero .column .label {
  padding-bottom: 0.1em;
  margin-bottom: 0;
  color: #d3d3d3;
}

.current-cluster-name {
  color: white;
  background: #3c2565;
}
#map-container {
  width: 100%;
}

#map-last-location {
  height: 200px;
}
</style>
