<template>
  <div id="app">
    <MenuComponent />
    <router-view :key="$route.path"></router-view>
    <DialogComponent></DialogComponent>
  </div>
</template>

<script>
import DialogComponent from "Components/DialogComponent.vue";
import { GET_USER_ME_ACTION } from "Stores/users";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import MenuComponent from "Components/semantic-menu/MenuComponent.vue";

export default {
  name: "app",
  mounted() {
    // need to get user data
    // to check if user is authorized/unauthorized
    this.getUserMe().catch(e => {
      this.setError({
        message: "Impossible de récupérer l'utilisateur courant <br />",
        error: e
      });
    });
  },
  methods: mapActions({
    getUserMe: GET_USER_ME_ACTION,
    setError: SET_ERROR_ACTION
  }),
  components: {
    MenuComponent,
    DialogComponent
  }
};
</script>

<style lang="scss">
// Customize theme
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #4a4a4a;
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "twitter": (
    $twitter,
    $twitter-invert
  ),
  "text": (
    $light,
    $dark
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

tr.is-danger {
  background-color: $danger;
  color: white;
}
tr.is-warning {
  background-color: $warning;
}
body {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("./assets/background-homepage.jpg") center center;
  background-size: cover;
  overflow-x: hidden;
}
:root {
  --green: #01a76a;
  --light-green: #45ca8d;
  --light-blue: #019fa7;
  --blue: #016aa7;
  --red: #d05f5f;
  --orange: #d0935f;
  --pink: #d05f79;
  --light-pink: #e5899e;
  --yellow: #e0bb33; /* #e6c651; */
}
.message.is-green .message-header {
  background: var(--green);
}
.message.is-light-green .message-header {
  background: var(--light-green);
}
.message.is-light-blue .message-header {
  background: var(--light-blue);
}
.message.is-blue .message-header {
  background: var(--blue);
}
.message.is-red .message-header {
  background: var(--red);
}
.message.is-pink .message-header {
  background: var(--pink);
}
.message.is-orange .message-header {
  background: var(--orange);
}
.message.is-yellow .message-header {
  background: var(--yellow);
}
.message.is-blue .message-header {
  background: var(--blue);
}
.message.is-red .message-header {
  background: var(--red);
}
.message.is-pink .message-header {
  background: var(--pink);
}
.message.is-light-pink .message-header {
  background: var(--light-pink);
}
.message.is-orange .message-header {
  background: var(--orange);
}
.message.is-yellow .message-header {
  background: var(--yellow);
}

.tabs > div > ul > li.is-active > a {
  color: $primary !important;
}
.tabs > ul > li > a.is-green {
  color: white !important;
  background: var(--green) !important;
  border-color: var(--green) !important;
}
.tabs > ul > li > a.is-light-green {
  color: white !important;
  background: var(--light-green) !important;
  border-color: var(--light-green) !important;
}
.tabs > ul > li > a.is-light-blue {
  color: white !important;
  background: var(--light-blue) !important;
  border-color: var(--light-blue) !important;
}
.tabs > ul > li > a.is-blue {
  color: white !important;
  background: var(--blue) !important;
  border-color: var(--blue) !important;
}
.tabs > ul > li > a.is-yellow {
  color: white !important;
  background: var(--yellow) !important;
  border-color: var(--yellow) !important;
}
.tabs > ul > li > a.is-pink {
  color: white !important;
  background: var(--pink) !important;
  border-color: var(--pink) !important;
}
.tabs > ul > li > a.is-light-pink {
  color: white !important;
  background: var(--light-pink) !important;
  border-color: var(--light-pink) !important;
}
.tabs > ul > li > a.is-orange {
  color: white !important;
  background: var(--orange) !important;
  border-color: var(--orange) !important;
}
.tabs > ul > li > a.is-red {
  color: white !important;
  background: var(--red) !important;
  border-color: var(--red) !important;
}

.tag.is-green {
  color: white;
  background: var(--green);
}
.tag.is-light-green {
  color: white;
  background: var(--light-green);
}
.tag.is-light-blue {
  color: white;
  background: var(--light-blue);
}
.tag.is-blue {
  color: white;
  background: var(--blue);
}
.tag.is-red {
  color: white;
  background: var(--red);
}
.tag.is-pink {
  color: white;
  background: var(--pink);
}
.tag.is-orange {
  color: white;
  background: var(--orange);
}
.tag.is-yellow {
  color: white;
  background: var(--yellow);
}
.tag.is-blue {
  color: white;
  background: var(--blue);
}
.tag.is-red {
  color: white;
  background: var(--red);
}
.tag.is-pink {
  color: white;
  background: var(--pink);
}
.tag.is-light-pink {
  color: white;
  background: var(--light-pink);
}
.tag.is-orange {
  color: white;
  background: var(--orange);
}
.tag.is-yellow {
  color: white;
  background: var(--yellow);
}

.hero--title > .hero-body {
  padding: 1.5em 1.5em;
}
.modal-card {
  overflow: visible;
}
.modal-card-body {
  overflow: visible;
}
</style>
