<template>
  <div class="container">
    <Loader :status="loaderStatus" />
    <img class="karnott-img" src="/images/logo-white.svg" />
    <section class="hero is-bold is-small">
      <div class="hero-body">
        <div class="container">
          <form class="title">
            <div class="field">
              <b-input v-model="searchValue" @input="onSubmit" placeholder="Rechercher"></b-input>
            </div>
          </form>
        </div>
      </div>
    </section>

    <div class="results">
      <router-link
        v-for="cluster in results.clusters"
        :key="`cluster-${cluster.id}`"
        class="box"
        :to="`/cluster/${cluster.id}`"
      >
        <div class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <span class="tag" :class="selectClusterTypeColor(cluster.cluster_type)" v-if="cluster.current_state">
                  {{ cluster.cluster_type.slice(0, 4) }}
                </span>
                <span
                  class="tag"
                  :class="selectClusterCurrentStateColor(cluster.current_state)"
                  v-if="cluster.current_state"
                >
                  {{ cluster.current_state.slice(0, 4) }}
                </span>
                <strong> {{ cluster.name }} </strong>
              </p>
            </div>
          </div>
        </div>
      </router-link>

      <router-link v-for="user in results.users" :key="`user-${user.id}`" class="box" :to="`/user/${user.id}`">
        <div class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <span class="tag is-warning"> Utilisateur </span>
                <span class="tag is-info"> {{ user.application_role }} </span>
                <strong> {{ user.fullname }} </strong>
                <span> ({{ user.email }}) </span>
              </p>
            </div>
          </div>
        </div>
      </router-link>

      <router-link
        v-for="device in results.devices"
        :key="`device-${device.id}`"
        class="box"
        :to="`/device/${device.id}`"
      >
        <div class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <span class="tag is-dark"> Karnott </span>
                <span class="tag is-danger" v-if="isDeviceOutOfService(device)">
                  <b-icon icon="skull-crossbones" size="is-small"></b-icon>
                </span>
                <span class="tag is-success" v-if="isDeviceOk(device)">
                  <b-icon icon="thumb-up" size="is-small"></b-icon>
                </span>
                <span class="tag is-warning" v-if="isDeviceDown(device)">
                  <b-icon icon="alert-circle" size="is-small"></b-icon>
                </span>
                <span class="tag is-danger" v-if="isDeviceCritical(device)">
                  <b-icon icon="alert" size="is-small"></b-icon>
                </span>
                <strong> {{ device.serialnumber }} </strong>
              </p>
            </div>
          </div>
        </div>
      </router-link>
      <router-link
        v-for="beacon in results.beacons"
        :key="`beacon-${beacon.uuid}`"
        class="box"
        :to="`/beacon/${beacon.uuid}`"
      >
        <div class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <span class="tag is-success"> Beacon </span>
                <span :class="`tag ${getBeaconTypeCssClassName(beacon.type)}`"> {{ beacon.type }} </span>
                <span> {{ beacon.uuid }} </span>
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.karnott-img {
  display: block;
  width: 400px;
  margin: -50px auto -20px;
}
.hero {
  background: #fff;
}
form.title label.label {
  color: white !important;
}
.box {
  margin-top: 10px;
  margin-bottom: 0px !important;
  cursor: pointer;
}
.box:hover {
  background: #fafafa;
}
.results {
  margin-bottom: 20px;
}
.box span + strong,
.box span + span {
  margin-left: 5px;
}
</style>

<script>
import { search } from "Api/search";
import {
  isDeviceOutOfService,
  isDeviceOk,
  isDeviceDown,
  isDeviceCritical,
  callApiWithSignal,
  getBeaconTypeCssClassName,
  getClusterCurrentStateColor,
  sortClusters
} from "Utils";
import Loader from "Components/Loader";
import { getClusterTypeColor } from "Utils";

var timeout = null;
export default {
  components: { Loader },
  data() {
    return {
      loaderStatus: "hide",
      searchValue: "",
      signal: null,
      results: {
        clusters: [],
        devices: [],
        users: [],
        beacons: []
      }
    };
  },
  methods: {
    selectClusterTypeColor: cluster_type => getClusterTypeColor(cluster_type),
    selectClusterCurrentStateColor: current_state => getClusterCurrentStateColor(current_state),
    getBeaconTypeCssClassName(type) {
      return getBeaconTypeCssClassName(type);
    },
    isDeviceOutOfService(device) {
      return isDeviceOutOfService(device);
    },
    isDeviceOk(device) {
      return isDeviceOk(device);
    },
    isDeviceDown(device) {
      return isDeviceDown(device);
    },
    isDeviceCritical(device) {
      return isDeviceCritical(device);
    },
    onSubmit() {
      clearTimeout(timeout);
      if (this.signal) {
        this.signal.cancel();
      }
      const qLowerCase = this.searchValue.toLowerCase();
      // because aa & aaa search filter return too many results
      if (qLowerCase.length < 2 || qLowerCase == "aaa" || qLowerCase == "aa") {
        return;
      }
      timeout = setTimeout(() => {
        const { apiClient } = this.$store.getters;
        this.loaderStatus = "show";
        const { apiFunc, signal } = callApiWithSignal(search, apiClient);
        this.signal = signal;
        apiFunc({ q: this.searchValue })
          .then(({ data }) => {
            const { clusters, devices, users, beacons } = data;
            this.results = { clusters: clusters.sort(sortClusters), devices, users, beacons };
            this.loaderStatus = "hide";
          })
          .catch(e => {
            this.loaderStatus = "hide";
            if (e.code !== "ERR_CANCELED") {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            }
          });
      }, 300);
    }
  }
};
</script>
