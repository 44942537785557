<template>
  <div class="container is-fullhd">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Karnott en stock</h1>
          <div class="columns is-multiline">
            <div class="column is-full">
              <label class="label">Filtrer par statut</label>
              <b-field>
                <b-checkbox-button
                  @input="onChangeCheckbox('selectedStatusFilter')"
                  :native-value="ALL"
                  v-model="selectedStatusFilter"
                  type="is-danger"
                >
                  <span> Tous </span>
                </b-checkbox-button>
                <b-checkbox-button
                  @input="onChangeCheckbox('selectedStatusFilter')"
                  :native-value="state"
                  :key="state"
                  v-model="selectedStatusFilter"
                  v-for="state in deviceStatus"
                  type="is-warning"
                >
                  <span> {{ state }} ({{ devices.filter(d => d.status === state).length }})</span>
                </b-checkbox-button>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section has-background-white">
      <div class="devices-list">
        <b-table
          :loading="isLoading"
          :data="devicesFiltered"
          :paginated="true"
          :per-page="100"
          :default-sort="['id', 'desc']"
        >
          <b-table-column v-slot="props" field="serialnumber" label="Numéro de série" sortable>
            <router-link class="button is-text" :to="`/device/${props.row.id}`">
              {{ props.row.serialnumber }}
            </router-link>
          </b-table-column>
          <b-table-column v-slot="props" field="batterie" label="Bat." sortable>
            <span class="tag is-medium" :class="{ 'is-danger': props.row.external_battery_level < 85 }">
              <b-icon
                :icon="getBatteryIcon(props.row.external_battery_level, props.row.charging_state)"
                size="is-small"
              ></b-icon>

              <span>{{ props.row.external_battery_level }}%</span>
            </span>
          </b-table-column>
          <b-table-column v-slot="props" field="firmware_name" label="Firmware" sortable>
            <span
              class="tag is-medium"
              :class="{ 'is-danger': props.row.firmware_name !== firmwares_validation?.description }"
            >
              {{ props.row.firmware_name }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" field="hardware_version" label="HardwareID" sortable centered>
            {{ props.row.hardware_version }}
          </b-table-column>
          <b-table-column v-slot="props" field="status" label="Statut" sortable>
            <span>
              {{ props.row.status }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" field="last_connection_date" label="Dernière connexion" sortable>
            <span>
              {{ props.row.last_connection_date_string }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" field="actions" label="Actions" sortable>
            <button class="button is-info" @click="setAvailable(props.row)">
              <span>Passer en AVAILABLE</span>
            </button>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { searchDevices, changeStatus } from "Api/device";
import { FETCH_FIRMWARES_ACTION } from "Stores/devices";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { GET_USER_ME_ACTION } from "Stores/users";
import { DEVICE_STOCK_STATUS, DEVICE_STOCK_REHAB_STATUS, DEVICE_STOCK_LENDING_STATUS } from "Constants";

const ALL = "ALL";

export default {
  mounted() {
    document.title = "Karnott en stock";
    const { selectedStatusFilter } = this.$route.query;
    if (selectedStatusFilter) {
      if (typeof selectedStatusFilter === "string") {
        this.selectedStatusFilter = [selectedStatusFilter];
      } else {
        this.selectedStatusFilter = selectedStatusFilter;
      }
    }
    this.filtersUpdated();

    this.getCurrentUser().then(user => (this.currentUser = user));
    this.getFirmwares();
  },
  methods: {
    resetCheckbox(prop) {
      this[prop] = [ALL];
    },
    onChangeCheckbox(propString) {
      const propValue = this[propString];
      const arrayLen = propValue.length;
      if (arrayLen === 0) {
        this.resetCheckbox(propString);
        this.filtersUpdated();
        return;
      }
      if (propValue[arrayLen - 1] === ALL) {
        this.resetCheckbox(propString);
        this.filtersUpdated();
        return;
      }
      if (propValue.find(s => s === ALL)) {
        this[propString] = propValue.filter(s => s !== ALL);
      }
      this.filtersUpdated();
    },
    filtersUpdated() {
      const { selectedStatusFilter } = this;
      let params = {};
      if (selectedStatusFilter && selectedStatusFilter.length > 0 && !selectedStatusFilter.includes(ALL)) {
        params.status = selectedStatusFilter;
      }
      const query = Object.assign({}, this.$route.query);
      query.selectedStatusFilter = selectedStatusFilter;
      this.$router.push({ query }).catch(() => {});

      this.getDevices(params);
    },
    getBatteryIcon(external_battery_level, charging_state) {
      if (!external_battery_level) {
        return "battery-off-outline";
      }
      if (charging_state === "CHARGED") {
        return "power-plug-battery";
      }
      if (charging_state === "CHARGING") {
        return `battery-charging-${Math.ceil(external_battery_level / 10) * 10}`;
      }
      if (external_battery_level > 90) {
        return `battery`;
      }
      return `battery-${Math.ceil(external_battery_level / 10) * 10}`;
    },
    getDevices(params) {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      const queryParams = {
        is_demo: false,
        status: [DEVICE_STOCK_STATUS, DEVICE_STOCK_REHAB_STATUS, DEVICE_STOCK_LENDING_STATUS],
        withoutTracks: true
      };

      if (!params?.status) {
        params.status = [DEVICE_STOCK_STATUS, DEVICE_STOCK_REHAB_STATUS, DEVICE_STOCK_LENDING_STATUS];
      }

      searchDevices(
        apiClient,
        null,
        null
      )(queryParams)
        .then(({ data }) => {
          this.devices = data.map(d => {
            if (d.last_connection_date) {
              const date = new Date(Date.parse(d.last_connection_date));
              d.last_connection_date_string = date.toLocaleDateString();
              d.last_connection_date = date;
            }
            return d;
          });

          this.devicesFiltered = this.devices.filter(d => params?.status?.includes(d.status));

          this.isLoading = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    },
    getFirmwares() {
      this.fetchFirmwares().then(() => {
        this.firmwares = this.$store.state.devices.firmwares;
        this.firmwares_validation = this.firmwares.filter(f => f.is_valid)[0];
      });
    },
    setAvailable(device) {
      this.$buefy.dialog.confirm({
        message: `Mettre le Karnott ${device.serialnumber} AVAILABLE ?`,
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          const params = { deviceInstanceIds: [device.id], status: "AVAILABLE" };
          changeStatus(apiClient)(params)
            .then(() => {
              this.setSuccess({
                message: "Le statut a bien été mis à jour"
              });
              this.filtersUpdated();
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION,
      fetchFirmwares: FETCH_FIRMWARES_ACTION,
      getCurrentUser: GET_USER_ME_ACTION
    })
  },
  data() {
    return {
      ALL,
      devices: [],
      devicesFiltered: [],
      firmwares: [],
      firmwares_validation: null,
      isLoading: false,
      currentUser: null,
      selectedStatusFilter: [ALL],
      deviceStatus: [DEVICE_STOCK_STATUS, DEVICE_STOCK_REHAB_STATUS, DEVICE_STOCK_LENDING_STATUS]
    };
  }
};
</script>

<style></style>
