<template>
  <section>
    <form @submit="onSubmit">
      <b-field label="Nom">
        <b-input v-model="cluster.name"> </b-input>
      </b-field>
      <b-field label="Adresse">
        <b-input v-model="cluster.address"> </b-input>
      </b-field>
      <b-field label="Details">
        <b-input v-model="cluster.details"> </b-input>
      </b-field>
      <b-field label="Type">
        <b-select v-model="cluster.cluster_type" placeholder="Choisir le type d'exploitation" expanded>
          <option v-for="type in CLUSTER_TYPES" :value="type" :key="type">
            {{ type }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Téléphone">
        <b-input v-model="cluster.phone"> </b-input>
      </b-field>
      <b-field label="Siren">
        <b-input v-model="cluster.siren"> </b-input>
      </b-field>
      <b-field label="Sellsy">
        <b-input v-model="cluster.sellsy_id"> </b-input>
      </b-field>

      <button class="button is-success is-fullwidth">{{ butttonLabel }}</button>
    </form>
  </section>
</template>

<script>
import { updateCluster } from "Api/cluster";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { FARM_CLUSTER_TYPE, CLUSTER_TYPES } from "Constants";
import { mapActions } from "vuex";
import { createClusterWithState } from "Utils";

export default {
  props: {
    cluster: {
      type: Object,
      default() {
        return {
          name: "",
          address: "",
          details: "",
          phone: "",
          siren: "",
          sellsy_id: "",
          cluster_type: FARM_CLUSTER_TYPE
        };
      }
    },
    action: {
      type: String,
      default: "create"
    },
    afterSubmit: Function
  },
  computed: {
    butttonLabel() {
      const { action } = this;
      return {
        create: "Ajouter",
        update: "Modifier"
      }[action];
    }
  },
  data() {
    return {
      CLUSTER_TYPES: CLUSTER_TYPES
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const { cluster, action } = this;
      const { id, name, address, details, cluster_type, phone, siren, sellsy_id } = cluster;
      const params = {
        name,
        address,
        details,
        phone,
        siren,
        sellsy_id,
        cluster_type
      };
      const { apiClient } = this.$store.getters;
      if (action === "update") {
        updateCluster(apiClient)(id, params)
          .then(({ data }) => {
            this.setSuccess({
              message: "Exploitation modifiée avec succès"
            });
            if (this.afterSubmit) {
              this.afterSubmit(data);
            }
          })
          .catch(e => this.setError(e));
      } else {
        createClusterWithState(apiClient, params)
          .then(({ data }) => {
            this.setSuccess({
              message: "Exploitation créée avec succès"
            });
            if (this.afterSubmit) {
              this.afterSubmit(data);
            }
          })
          .catch(e => this.setError(e));
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  }
};
</script>
